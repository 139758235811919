<template>
  <v-container>
    <v-row justify="center">
      <v-col sm="10" md="8" lg="6">
        <v-sheet elevation="10" class="pa-8">
          <div v-show="!cartLoading && !stripeFormLoading && !cartError && !cartLoadingError && !checkoutSuccess && (!requiresAgreement || hasAgreement)">
            <div class="text-center">
              <p class="mb-2">Logged in as {{ user.full_name }} ({{ user.email }})</p>
              <small><a href="#" @click.prevent="logout">Is this not you?</a></small>
            </div>
            <v-divider class="mt-2 mb-5" />
            <v-row>
              <v-col cols="12" class="text-center">
                <h2>Review Your Order</h2>
              </v-col>
            </v-row>
            <v-divider class="mt-2 mb-5" />
            <v-row class="justify-between">
              <v-col cols="12">
                <v-simple-table v-if="cart.line_items">
                  <tbody>
                    <tr v-for="item in cart.line_items" :key="item.id">
                      <td>{{ item.variant.name }}</td>
                      <td class="text-right">x{{ item.quantity }}</td>
                      <td>${{ item.unit_price }}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td class="text-right">Subtotal</td>
                      <td>${{ cart.subtotal }}</td>
                    </tr>
                    <tr v-if="cart.total_discount !== '0.00'">
                      <td></td>
                      <td class="text-right">Discount</td>
                      <td>-${{ cart.total_discount }}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td class="text-right">Total</td>
                      <td>${{ cart.total }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <h2>Payment Information</h2>
              </v-col>
            </v-row>
            <v-divider class="mt-2 mb-4" />
            <v-row>
              <v-col cols="12">
                <v-tabs fixed-tabs active-class="active-tab" v-model="tab">
                  <v-tab key="savedCard" v-if="user.credit_cards.length > 0">
                    Saved Card
                  </v-tab>
                  <v-tab key="newCard">
                    New Card
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" v-show="!checkoutPending">
                  <v-tab-item v-if="user.credit_cards.length > 0">
                    <v-col cols="12" class="mt-5">
                      <v-snackbar
                        color="red"
                        v-model="paymentErrored"
                        timeout="10000"
                        >
                        <span v-for="(error, i) in paymentError" :key="i">{{ error }}</span>
                      </v-snackbar>
                      <v-list class="mb-5" rounded>
                        <v-list-item-group
                          color="primary"
                          v-model="selectedSavedCardIndex"
                        >
                          <v-list-item link v-for="item in user.credit_cards" :key="item.id">
                            <v-list-item-avatar>
                              <i v-if="item.card_type === 'American Express'" class="fa fa-cc-amex" style="font-size:24px"></i>
                              <i v-if="item.card_type === 'Visa'" class="fa fa-cc-visa" style="font-size:24px"></i>
                              <i v-if="item.card_type === 'Discover'" class="fa fa-cc-discover" style="font-size:24px"></i>
                              <i v-if="item.card_type === 'Mastercard'" class="fa fa-cc-mastercard" style="font-size:24px"></i>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {{ item.last_four }}
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-btn :disabled="!selectedSavedCard" depressed block color="primary" large @click.prevent="submitSavedCardCheckout">Checkout</v-btn>
                    </v-col>
                  </v-tab-item>

                  <v-tab-item>
                    <v-col cols="12" class="mt-5">
                      <form id="payment-form">
                        <label for="payment-element">
                          Credit or Debit Card
                        </label>
                        <div id="payment-element" class="v-text-field">
                          <!--Stripe.js injects the Payment Element-->
                        </div>
                        <v-checkbox v-model="store" :disabled="requireCard === 1" label="Save my card" :messages="(requireCard === 1) ? 'This purchase requires saving a card to your account.' : null" />
                        <v-btn block large depressed @click.prevent="submitOrder" id="submit" color="primary" class="mt-2">
                          <div class="spinner hidden" id="spinner"></div>
                          <span id="button-text">Checkout</span>
                        </v-btn>
                        <div id="payment-message" class="hidden"></div>
                      </form>
                    </v-col>
                  </v-tab-item>

                </v-tabs-items>
              </v-col>

              <v-col sm="12" v-show="checkoutPending">
                <v-row justify="center" class="text-center">
                  <v-col cols="8">
                    <heart-loader />
                    <h3>{{checkoutPendingMessage}}</h3>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <div v-if="!cartLoading && !stripeFormLoading && !cartError && !cartLoadingError && !checkoutSuccess && requiresAgreement && !hasAgreement">
            <v-row justify="center" class="text-center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" class="text-center">
                    <h2>Purchase Agreement</h2>
                  </v-col>
                </v-row>
                <v-divider class="mt-2 mb-5" />
                <div style="width: 100%; max-height: 300px; padding: 15px; overflow-y: scroll; border: 1px solid #e2e2e3; text-align: left; margin-bottom: 20px;" v-html="purchaseAgreement.text"></div>
                
                <h4 class="text-center mb-2">Signature</h4>
                <div style="width: 100%; border: 1px solid #e2e2e3; margin-bottom: 15px;">
                  <VueSignaturePad width="100%" height="180px" ref="signaturePad" />
                </div>
                <v-btn color="primary" block depressed large @click.prevent="submitAgreement">I agree</v-btn>
              </v-col>
            </v-row>
          </div>

          <div v-if="cartLoading || stripeFormLoading && !cartLoadingError">
            <v-row justify="center" class="text-center">
              <v-col cols="6">
                <heart-loader />
                <h3>Loading Cart...</h3>
              </v-col>
            </v-row>
          </div>

          <div v-if="!cartLoading && cartError || cartLoadingError">
            <v-row justify="center" class="text-center">
              <v-col cols="6">
                <v-icon style="font-size: 9rem;">mdi-emoticon-sad-outline</v-icon>
                <h3 class="py-5" v-if="!cartLoadingError">Oh no! Something went wrong getting your cart ready!</h3>
                <h3 class="py-5" v-if="cartLoadingError">{{cartLoadingError}}</h3>
                <v-btn :href="originalLink" depressed color="primary">Click here to try again</v-btn>
              </v-col>
            </v-row>
          </div>

          <div v-if="!cartLoading && !cartError && !checkoutPending && checkoutSuccess">
            <v-row justify="center" class="text-center">
              <v-col cols="6">
                <v-icon style="font-size: 9rem;">mdi-emoticon-happy-outline</v-icon>
                <h3 class="py-5">Thank you for your purchase!</h3>
                <v-btn href="https://app.rayoga.com/my-account/?_mt=%2Faccount%2Fpersonal-information" depressed color="primary">My Account</v-btn>
              </v-col>
            </v-row>
          </div>

          <div v-if="!cartLoading && !cartError && !checkoutPending && checkoutErrored">
            <v-row justify="center" class="text-center">
              <v-col cols="6">
                <v-icon style="font-size: 9rem;">mdi-emoticon-sad-outline</v-icon>
                <h3 class="py-5">{{checkoutError}}</h3>
                <v-btn :href="originalLink" depressed color="primary">Click here to try again</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeartLoader from "@/components/heartLoader"
const Stripe = window.Stripe;
import axios from 'axios'

export default {
  name: 'CartView',
  components: {
    HeartLoader,
  },

  data() {
    return {
      tab: null,
      selectedSavedCardIndex: null,
      stripe: null,
      cardholder: null,
      cardnumber: null,
      expmonth: null,
      expyear: null,
      ccv: null,
      zipcode: null,
      store: false,
      stripeFormLoading: false,
      elements: null,
      paymentElement: null,
      months: [
        { val: '1', name: 'Jan'},
        { val: '2', name: 'Feb'},
        { val: '3', name: 'Mar'},
        { val: '4', name: 'Apr'},
        { val: '5', name: 'May'},
        { val: '6', name: 'Jun'},
        { val: '7', name: 'Jul'},
        { val: '8', name: 'Aug'},
        { val: '9', name: 'Sept'},
        { val: '10', name: 'Oct'},
        { val: '11', name: 'Nov'},
        { val: '12', name: 'Dec'},
      ],
      years: [
        '2022',
        '2023',
        '2024',
        '2025',
        '2026',
        '2027',
        '2028',
        '2029',
        '2030',
        '2031',
        '2032',
        '2033',
        '2034',
        '2035',
        '2036',
        '2037',
      ],
    }
  },

  computed: {
    user() {
      return this.$store.state.user
    },
    cart() {
      return this.$store.state.cart
    },
    cartLoading() {
      return this.$store.state.cartLoading
    },
    cartLoadingError() {
      return this.$store.state.cartLoadingError
    },
    requireCard() {
      return this.$store.state.require_card
    },
    originalLink() {
      return this.$store.state.originalLink
    },
    cartError() {
      return (typeof(this.cart.line_items) === 'undefined' || this.cart.line_items.length === 0)
    },
    checkoutPending() {
      return this.$store.state.checkoutPending
    },
    checkoutPendingMessage() {
      return this.$store.state.checkoutPendingMessage
    },
    checkoutSuccess() {
      return this.$store.state.checkoutSuccess
    },
    checkoutErrored() {
      return this.$store.state.checkoutErrored
    },
    checkoutError() {
      return this.$store.state.checkoutError
    },
    order () {
      return this.$store.state.order
    },
    paymentErrored() {
      return this.$store.state.paymentErrored
    },
    paymentError() {
      return this.$store.state.paymentError
    },
    pendingPurchaseAgreement() {
      return this.$store.state.pendingPurchaseAgreement
    },
    purchaseAgreement() {
      return this.$store.state.purchaseAgreement
    },
    requiresAgreement() {
      return this.pendingPurchaseAgreement !== null && this.pendingPurchaseAgreement.pending_signatures.length > 0
    },
    hasAgreement() {
      return this.$store.state.signedPurchaseAgreement !== null
    },
    selectedSavedCard() {
      return this.user.credit_cards[this.selectedSavedCardIndex]
    }
  },

  watch: {
    tab(val) {
      if (val === 1 && this.stripe === null) {
        setTimeout(() => {
          this.stripeFormLoading = true
          //console.log('initializing stripe')
          this.initialize()
          this.stripeFormLoading = false
        }, 300)
      }
    }
  },

  methods: {
    logout() {
      const originalLink = this.$store.state.originalLink
      window.localStorage.removeItem('vuex')
      window.location.href = originalLink + '&prompt=1'
    },
    async initialize() {
      const { client_secret, stripe_publishable_api_key } = this.$store.state.paymentIntent;
      this.stripe = Stripe(stripe_publishable_api_key)

      const appearance = {
        theme: 'stripe',
      };

      const style = {
        base: {
          color: '#303238',
          fontSize: '16px',
          fontFamily: '"Open Sans", sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#CFD7DF',
          },
        },
        invalid: {
          color: '#e5424d',
          ':focus': {
            color: '#303238',
          },
        },
      };

      this.elements = this.stripe.elements({ appearance, clientSecret: client_secret });

      this.cardElement = this.elements.create('card', { style });
      this.cardElement.mount("#payment-element");
    },
    submitOrder() {
      //console.log('submitting order')
      const checkCartStatus = this.checkCartStatus()
      this.$store.commit('setCheckoutPendingMessage', 'Processing payment...')
      this.$store.commit('setCheckoutPending', true)
      this.stripe.confirmCardPayment(this.$store.state.paymentIntent.client_secret, {
        payment_method: {
          card: this.cardElement,
        },
        setup_future_usage: (this.store) ? 'off_session' : null,
      })
      .then(async (result) => {
        if (result.error.code) {
          this.$store.commit('setCheckoutError', result.error.message)
          this.$store.commit('setCheckoutErrored', true)
          this.$store.commit('setCheckoutPendingMessage', null)
          this.$store.commit('setCheckoutPending', false)
        } else {
          this.$store.dispatch('updateTracking', {
            checkoutStep: 'CHECKOUT_SUBMITTED'
          })
          // start long polling cart status
          await checkCartStatus()
        }
      });
    },
    async checkCartStatus() {
      //console.log('checking cart status')
      //const checkCartStatus = this.checkCartStatus()
      this.$store.commit('setCheckoutPendingMessage', 'Checking out...')
      const completed = await axios.post(`${this.$store.state.baseEccoUrl}/mariana/stripe-payment-status`, {
          cart: this.cart.id
        })
        .then(async (result) => {
          if (result.data.status === 'Submitted') {
            this.$store.commit('setCheckoutSuccess', true)
            this.$store.commit('setCheckoutPendingMessage', null)
            this.$store.commit('setCheckoutPending', false)
            this.$store.dispatch('updateTracking', {
              checkoutStep: 'PAYMENT_SUCCESS_ORDER_COMPLETE'
            })

            window.dataLayer.push({
              'event':'purchaseCompleted',
              'value': this.cart.total,
              'transaction_id': this.$store.state.mtek_id,
              'currency': 'USD'
            });

            window.dataLayer.push({
              'event':'orderCompleted',
              'value': this.cart.total,
              'transaction_id': this.$store.state.mtek_id,
              'currency': 'USD'
            });

            return true
          } else if (result.data.status === 'Failed' ) {
            this.$store.commit('setCheckoutError', result.data.error)
            this.$store.commit('setCheckoutPendingMessage', null)
            this.$store.commit('setCheckoutPending', false)
            this.$store.commit('setCheckoutErrored', true)
            this.$store.dispatch('updateTracking', {
              checkoutStep: 'PAYMENT_ERROR_ORDER_FAILED'
            })
            return true
          } else if (result.data.status === 'Open') {
            this.$store.dispatch('updateTracking', {
              checkoutStep: 'PAYMENT_OPEN_ORDER_PENDING'
            })
            return false
          }
        })

      if (!completed) {
        setTimeout(this.checkCartStatus(), 2000)
      }
    },
    wait(ms) {
      return new Promise((resolve) => setTimeout (resolve, ms))
    },
    async submitAgreement() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/png');
      if (!isEmpty) {
        console.log(data)
        await this.$store.commit('setSignatureImage', this.convertURItoBlob(data))
        await this.$store.dispatch('signPurchaseAgreement')
      } else {
        alert('We need your signature')
      }
    },
    convertURItoBlob(dataurl) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataurl.split(',')[1]);

      // separate out the mime component
      //var mimeString = dataurl.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      var ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], {type: 'image/png'});

      return blob;
      /*var arr = dataurl.split(','), //mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type:'png'});*/
    },
    async submitSavedCardCheckout() {
      this.$store.commit('setCheckoutPending', true)
      await axios.post(`${this.$store.state.marianaBaseUri}/api/customer/v1/locations/${this.user.home_location.id}/cart/checkout`, 
      { payments: [
        {
          amount: this.cart.total,
          payment_method_type: 'bankcard',
          payment_method: {
            id: this.selectedSavedCard.id
          }
        }
      ]},{
            headers: {
              'Authorization': `Bearer ${this.$store.state.session.access_token}`
            },
        })
      .then(async (result) => {
        console.log(result)
        this.$store.dispatch('updateTracking', {
          checkoutStep: 'SAVED_CARD_CHECKOUT_SUBMITTED'
        })
        await this.checkCartStatus()
      }).catch((error) => {
        console.log(error)
        this.$store.commit('setCheckoutError', 'There was an issue with your saved card. It may be expired or has been declined. Please try again with a different card or add a new card to your account.')
        this.$store.commit('setCheckoutErrored', true)
        this.$store.commit('setCheckoutPending', false)
      })
    },
  },

  async mounted() {
    this.$store.commit('setCartLoading', true)
    await this.$store.dispatch('clearCart')
    await this.$store.dispatch('generateCart')
    await this.$store.dispatch('getStripeConfig')
    await this.$store.dispatch('createStripePaymentIntent')
     console.log('getting pending')
    if (this.cart.are_purchase_agreements_required) {
      await this.$store.dispatch('getPendingPurchaseAgreement')
      if (this.$store.state.pendingPurchaseAgreement !== null) {
        console.log('getting purchase agreement')
        await this.$store.dispatch('getPurchaseAgreement')
      }
    }
    this.$store.commit('setCartLoading', false)

    if (this.requireCard == true) {
      this.store = true
    }

    this.$store.commit('setCheckoutErrored', false)
    this.$store.commit('setCheckoutError', null)

    if (this.user.credit_cards.length > 0) {
      this.tab = 0
      this.selectedSavedCardIndex = 0
    } else {
      this.tab = 1
    }
  }
}
</script>

<style>
#payment-element {
  padding: 12px;
  border: 1px solid #e2e2e3;
  border-radius: 4px;
  box-shadow: 1px 1px 4px #e2e2e3;
  margin-bottom: 15px;
}

.v-list-item--link {
  background-color: #f1f1f1;
}

.active-tab {
  background-color: rgba(63, 82, 61, 0.15) !important;
}
</style>